.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 394px;
    height: 400px;
    border-radius: 10px;
    background-color: #f0f8ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content {
    background-color: #f0f8ff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    overflow-x: auto; 
    max-height: 500px; 
  }
  
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px;
    cursor: pointer;
    background: none;
    border: none;
    color: gray;
    font-size: 24px;
  }
  
  .button {
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .button-enabled {
    background-color: green;
  }
  
  .button-enabled:hover {
    background-color: darkgreen;
  }
  
  .button-disabled {
    background-color: red;
  }
  
  .button-disabled:hover {
    background-color: darkred;
  }
  
  .input-container {
    margin-bottom: 16px;
  }
  
  .input-label {
    display: block;
    margin-bottom: 8px;
  }
  .input {
    width: 50%;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 10px;
  }
  
  .footer {
    display: flex;
    justify-content: center;
    background-color: #1d4ed8;
    color: white;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    width: 137px;
    display: flex;
    justify-content: center;
    margin-right: 20px;
  }
  .feild_row{
    flex-direction: row;
  }
  
  .footer-button {
    background-color: #1d4ed8;
    color: white;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    width: 137px;
    display: flex;
    justify-content: center;
  }
  
  .footer-button:hover {
    background-color: #1e40af;
  }
  
  .Qcode {
    display: flex;
    flex-direction: column;
  }
  
  .Qcode-button {
    color: #1e40af;
    font-weight: bold;
    cursor: pointer;
    width: 100px;
  }
  
  .Qcode-fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    background-color: aliceblue;
    padding: 20px;
    border-radius: 20px;
  }
  


 /* Assuming this is in a global CSS file or in the component's style tag */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure modal is in front */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 70vh;
  width: 700px;
  overflow-y: auto;
}

.modal-header,
.modal-body,
.modal-footer {
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.hidden {
  display: none;
}
.modal-title{
  font-size: 20px;
  text-align: center;
}


.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 40%;
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card_body {
  padding: 2px 16px;
}